@use '@angular/material' as mat;
@include mat.core();
@import "https://s3.sa-east-1.amazonaws.com/us.framework.pjus.com.br/ds-pjus/web/dist/css/main.css";
@import "assets/scss/components/variables";
@import "assets/scss/fonts";
$parceriaUsSpa-primary: mat.define-palette($md-pjusthemeprimary);
$parceriaUsSpa-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$parceriaUsSpa-warn: mat.define-palette(mat.$red-palette);
$pjus-typography: mat.define-typography-config(
  $font-family: 'Lato-Regular'
);
$parceriaUsSpa-theme: mat.define-light-theme((
  color: (
    primary: $parceriaUsSpa-primary,
    accent: $parceriaUsSpa-accent,
    warn: $parceriaUsSpa-warn,
  ),
  typography: $pjus-typography,
));
@include mat.all-component-themes($parceriaUsSpa-theme);
@include mat.typography-hierarchy($pjus-typography);

html, body { height: 100%; }
body { margin: 0; font-family: "Lato-Regular", sans-serif; }

@import "assets/scss/main";

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0074FF;
  letter-spacing: normal;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  letter-spacing: normal;
}
.green-snackbar {
  background: rgb(65, 252, 134);
  color: white !important;
}
.scrollable-container-y {
  max-height: 416px;
  overflow-y: auto;
  overflow-x: hidden;
}
.progress {
  height: 5px;
  border-radius: 0px;
}
.progress-bar {
  height: 5px !important;
  border-radius: 0px;
}
.background-image-pjus {
  background-image: url('assets/bg/bg-fundo.png');
  background-size: cover;
  background-position: top;
}
//

.padding-auth {
  padding: 48px 88px !important;
  @media (max-width: 576px) {
    padding: 48px 16px !important;
  }
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: unset !important;
}
input[readonly] {
  background: #F7F7F7; /* Altere para a cor desejada */
}
.formulario-label {
  line-height: 22.5px;
}
.botao-salvar {
  width: 145px;
  padding: 3px;
}
.mat-drawer-content {
  background-color: #FFF;
}

.spinner-three-bounce {
  display: none !important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 12px !important;
}
.bg-secundario {
  background-color: #FCFCFC !important;
}
.cursor-pointer {
  cursor: pointer;
}
.shadow-none {
  outline: none !important;
  box-shadow: none !important;
}
.rotate-180 {
  transform: rotate(180deg);
}
input[readonly] {
  background: #F7F7F7 !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}
.even-background {
  background-color: #F7F7F7;
}


.texto-sucesso {
  color: #0E8557;
}

.linkou {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;

}
.texto-inter {
  font-family: 'Inter', sans-serif;
}
.bottom-tempo-expiracao {
  border-radius: 32px 32px 0 0;
  width: 326px;
  position: fixed;
  right: 2vh;
  top: calc(100vh - 380px);
  background-color: #FFF;
  box-shadow: 0px 22px 10px 0px #00000012;
}
.bottom-tempo-expiracao-mobile {
  border-radius: 32px 32px 0 0;
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #FFF;
  box-shadow: 0px 22px 10px 0px #00000012;
  border: 2px solid #D9D9D9;
}
.card-alerta {
  background-color: #F7F7F7;
  border-left: 10px solid #FEC33C;
  border-radius: 10px 0 0 10px;
  width: 96%
}
.borda-card-alerta {
  border-radius: 10px 0 0 10px;
}
