$hover-color-primary:  #3394FF;

.btn {
  font-family: "Lato-Regular", sans-serif;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  background-color: #FFF;
  border-color: #0074FF;
  color: #0074FF;
}
.btn-dashed {
  display: block;
  padding: 2px 15px;
  border-radius: 4px;
  background: none;
  border: dashed 1px #4D5858;
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  cursor: pointer;
  width: max-content;
   &:hover {
     border: dashed 1px #4D5858;
     color: #333333;
   }
}

.btn-primary {
  background-color: #0074FF;
  border-color: #0074FF;
  &:focus {
    background-color: $hover-color-primary !important;
    border-color: $hover-color-primary !important;
  }
  &:hover {
    background-color: $hover-color-primary !important;
    border-color: $hover-color-primary !important;
  }
  &:disabled {
    background-color: #4D5858 !important;
    border-color: #4D5858 !important;
    color: #FFF !important;
  }
}

.btn-outline-secondary {
  border-color: #333333;
  color: #333333;
  &:focus, &:focus-visible {
    border-color: #0074FF;
    color: #0074FF;
  }
  &:hover {
    border-color: $hover-color-primary;
    color: $hover-color-primary;
    background-color: transparent;
  }
  &:disabled {
    border-color: #DBDEDE;
    color: #DBDEDE;
  }
}
.btn-out-secondary {
  &:disabled {
    border-color: #4D5858 !important;
    color: #FFF !important;
    background-color: #4D5858;
  }
}

.btn-flutuante-direita {
  position: fixed !important;
  right: 2% !important;
  z-index: 1 !important;
  @media (max-width: 767.98px) {
    bottom: 5% !important;
  }
  @media (min-width: 768px) {
    bottom: 5% !important;
  }
}

a:link { text-decoration: none; color: #0074FF}
a:visited { text-decoration: none; color: #0074FF}
a:hover { text-decoration: none; color: #0074FF}
a:active { text-decoration: none; color: #0074FF}


.btn-medium {
  width: 133px;
  padding: 2px 15px 2px 15px;
  border-radius: 4px;
  gap: 10px;
}
.btn-sm-info{
  max-width: 119px;
  max-height: 28px;
}
.btn-dashed-danger {
  border-color: #FF4D4F;
}
