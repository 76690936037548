html, body {
  margin: 0;
  padding: 0;
}

/* Barra de rolagem apenas para Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
}

/*Barra de rolagem Chrome, Edge e Safari */
*::-webkit-scrollbar {
  width: 5px; //tamanho da barra de rolagem
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); //cor de fundo da barra
  border-radius: 6px;
}
*::-webkit-scrollbar-thumb {
  background-color: #B8BCBC; //cor da barra derolagem
  border-radius: 6px;
}

.text-link {
  text-decoration: underline #0074FF !important;
  color: #0074FF !important;
  cursor: pointer !important;
}
.cursor-pointer {
  cursor: pointer;
}
// LOGIN, NOVA SENHA, RECUPERAR SENHA
.bg-dark-pjus {
  background: #383941;
}
.login {
  width: 100%;
  max-width: 432px;
  margin: auto;
}
.auth {
  .card {
    border-radius: 8px !important;
  }
  .card-recuperar-senha {
    border-radius: 8px !important;
  }
}

.w-100-btn {
  width: 100px;
}

.mb-9 {
  margin-bottom: 9rem !important; // 16 * 9 = 144px
}
.mb-6 {
  margin-bottom: 6rem !important; // 6 * 9 = 54px
}

.center-progress {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-radius-8 {
  border-radius: 8px;
}

.dataview-mobile {
  width: 100%;
  height: 64px;
  background-color: #FFF;
  position: fixed;
  bottom: 0%;
  z-index: 400;
  border: solid 1px #DBDEDE;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
}

.rotate-180 {
  rotate: 180deg;
}
.rotate-180-neg {
  rotate: -180deg;
}

.card-animado-abertura{
  animation: fadeIn 1s ease-in both;
  transition: 0.1s;
}
.card-animado-oculto{
  animation: fadeOut 1s ease-in both;
  transition: 0.1s;
}

.animacao-trasicao-08 {
  transition: 0.8s;
}

// MATERIAL
.mat-mdc-tab-body {
  background-color: #F7F7F7 !important;
}
.mat-mdc-tab-body-wrapper {
  margin-top: 1.5rem;
}
.mat-bottom-sheet-container {
  border-radius: 32px 32px 0 0;
}

.sticky-top {
  z-index: 1000;
}

.mt-modal {
  position: relative;
  top: 2rem;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.w-max-content {
  width: max-content;
}
.border-cinza-noite {
  border-color: #4D5858 !important;
}
.bg-cinza-noite {
  background-color: #4D5858 !important;
}

.card-reprovacao {
  border-left: 6px solid #FF4D4F;
  background-color: #F7F7F7;
}

.w-200px {
  width: 200px;
}
.mb-14px {
  margin-bottom: 14px;
}


.h-3rem {
  height: 3rem !important;
}
.h-3-75rem {
  height: 3.75rem !important;
}

.bg-us {
  background: #F7F7F7 !important;
}

.ic {
  display: inline-block;
  vertical-align: text-bottom;
  width: 17px;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0.5s;
}

.ic-sm {
  width: 16px !important;
  height: 16px !important;
}

.ic-sm-large {
  width: 20px !important;
  height: 16px !important;
}

.ic-24px {
  width: 24px !important;
  height: 24px !important;
  background-size: cover !important;
}

.ic-check {
  background: url("../../assets/imgs/icones/check.svg");
}
.ic-trash {
  background: url("../../assets/imgs/icones/trash.svg");
}

.alert-success {
  background-color: #0E8557;
  border-color: #0E8557;
  border-radius: 4px;
  box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.13);
  span {
    color: #FFF;
  }
}

.border-radius-0 {
  border-radius: 0px 0px 0px 0px;
}

.w-80 {
  width: 80% !important;
}

@media (max-width: 767.98px) {
  .copy-card {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .copy-card {
    min-width: 375px;
  }
}



.padding-card-formulario {
  padding-inline: 25rem;
  padding-top: 3rem;
}

.invalid-feedback-inputs {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545 !important;
}

.margin-bottom-6px {
  margin-bottom: 6px !important;
}

.p-lateral-5px {
  padding: 0 5px;
}
.input-group-text {
  border-radius: .375rem 0 0 .375rem;
}

.vh-90-mobile {
  min-height: 90vh !important;
}
.vh-75-mobile {
  min-height: 75vh !important;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0074FF;
  --mdc-radio-selected-hover-icon-color: #0074FF;
  --mdc-radio-selected-icon-color: #0074FF;
  --mdc-radio-selected-pressed-icon-color: #0074FF;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #0074FF;
}
.mat-mdc-radio-button .mdc-radio {
  padding: 6px 0;
}
.h-max-content {
  height: max-content !important;
}

.po-calendar-range-picker {
  top: unset !important;
}

.card-circle-icon {
  width: 36px;
  height: 36px;
  padding: 8px 15px 8px 15px;
  border-radius: 100px;
  gap: 10px;
  box-shadow: 0px 4px 10px 0px #0000001A;
}
.vw-20 {
  width: 20vw;
}
.gestao {
  datatable-header {
    background-color: transparent !important;
    color: #B8BCBC;
  }
  datatable-body {
    background-color: #FFF;
  }
  datatable-body-cell {
    padding-inline: 8px;
    display: flex !important;
    align-items: center !important;
  }
}


.drawer-size {
  max-width: 360px;
  width: 100%;
}

.vertical-center {
  display: flex;
  align-items: center;
}

app-chip {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}
.mb-form-invalid {
  margin-bottom: 20px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.alerta-circulo {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  &-amarelo {
    background-color: #FEC33C;
  }
  &-sucesso {
    background-color: #0E8557;
  }
}
